import React from 'react';

import { Button } from 'antd'
import { UpOutlined } from '@ant-design/icons';
import { DownOutlined } from '@ant-design/icons';

import DynamicSelect from '../../../shared/DynamicSelect';

import { getStyle } from '../../../../styles/styles';
import { formatValidInput, formatValidSelect } from '../../../../helpers/FormatHelper';

import {    
    getReportSources, 
    getReportFieldsBySourceName,
    saveCustomReport,
    saveFieldsByCustomReports,    
    getFieldsByCustomReportId,    
} from '../../../../networking/NetworkingCustomReport';

import QueryBuilder from 'react-querybuilder';
import { formatQuery } from 'react-querybuilder';

import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Colors } from '../../../../constants/Colors';
import { de } from 'date-fns/locale';

var style = getStyle();

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});

const queryBuilderCSS = {
    combinators: 'uk-select uk-width-1-12',
    //rule: 'uk-grid',
    fields: 'uk-select uk-width-1-12',
    operators: 'uk-select uk-width-1-12',
    value: 'uk-input uk-width-2-12',
    //removeRule: 'uk-width-1-12'
};

const OrderBySection = (props) => {    

    const orderType = [{"value":"asc","name":"asc"},{"value":"desc","name":"desc"}]

    let { rightList, orderByColumns, orderByColumnsSelected, updateOrderByColumnsSelected } = props

    const selectedField = (fld) => {        

        if (fld === undefined) {
            if (orderByColumnsSelected.length === orderByColumns - 1)
                orderByColumnsSelected.push(rightList[0])
            updateOrderByColumnsSelected(orderByColumnsSelected)
            return
        }

        if (fld.controlID === undefined) {
            return
        }

        const words = fld.controlID.split('-');
        if (words.length === 2) {
            let idx = Number(words[1])
            if (orderByColumnsSelected.length >= idx) {
                orderByColumnsSelected[idx] = fld
                updateOrderByColumnsSelected(orderByColumnsSelected)
            }
        }
    }

    const selectedOrderType = (fld) => {        

        if (fld === undefined) {
            return
        }

        if (fld.controlID === undefined) {
            return
        }

        const words = fld.controlID.split('-');
        
        if (words.length === 2) {
            let idx = Number(words[1])
            
            if (orderByColumnsSelected.length >= idx) {
                let matchingColumn = orderByColumnsSelected[idx]
                matchingColumn.orderBy = fld.value
                orderByColumnsSelected[idx] = matchingColumn
                updateOrderByColumnsSelected(orderByColumnsSelected)
            }
        }
    }

    const removeField = (fld) => {

        if (fld.target === undefined) {
            return
        }

        if (fld.target.id === undefined) {
            return
        }

        let words = fld.target.id.split('-');

        if (words.length === 2) {
            let idx = Number(words[1])

            if (orderByColumnsSelected.length >= idx) {
                let newOrderByColumnsSelected = orderByColumnsSelected
                newOrderByColumnsSelected.splice(idx, 1)
                updateOrderByColumnsSelected(newOrderByColumnsSelected)
            }
        }
    }

    let sortByList = []
    for (var i = 0; i < orderByColumns; i++) {
        let value = {}
        let orderTypeValue = { "value": "asc", "name": "asc" }
        if (orderByColumnsSelected.length > i) {
            value = orderByColumnsSelected[i]
            orderTypeValue = { 'value': orderByColumnsSelected[i].orderBy, 'name': orderByColumnsSelected[i].orderBy }
        }
        
        sortByList.push(<div className="uk-grid" style={{marginTop: '0px'}} key={"div-formcustomreportsource-" + i}>
            <div className="uk-width-1-3" key={"div-a-formcustomreportsource-" + i}>
                <DynamicSelect
                    id={"formcustomreportsource-" + i}
                    key={"formcustomreportsource-" + i}
                    className={formatValidSelect(true)}
                    objValue={value}
                    getValue={f => selectedField(f)}
                    listHasPlaceholder={false}
                    data={rightList} />
            </div>
            <div className="uk-width-1-6" key={"div-b-formcustomreportsource-" + i}>
                <DynamicSelect
                    id={"formcustomreportsourceordertype-" + i}
                    key={"formcustomreportsourceordertype-" + i}
                    className={formatValidSelect(true)}
                    objValue={orderTypeValue}
                    getValue={f => selectedOrderType(f)}
                    listHasPlaceholder={false}
                    data={orderType} />
            </div>
            <button onClick={f => removeField(f)} key={"bdelete-" + i} id={"bdelete-" + i} style={{padding: '4px'}}>x</button>
        </div>
        )
    }

    return <>
        {sortByList}
    </>
}

const DialogTitle = withStyles(styles)((props) => 
{
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});

export default class CustomReportsV2Form extends React.Component {
    
    state = {
        loading: false,
        customReportId: 0,
        sourceList: [],
        reportName: '',
        sourceSelected: '',
        reportFieldsList: [],
        selectedIDRowLeft: '',
        selectedIDRowRight: '',
        leftList: [],
        rightList: [],
        customReportsList: [],

        //Validations
        validationReportName: true,
        validationSource: true,
        validationRightList: true,

        //Dialog
        customReportName: '',
        dataCustomReportList: [],
        totalPages: 0,
        currentPage: 1,
        numberPerPage: 10,
        open: false,
        fullWidth: true,
        maxWidth: 'md',

        //Alert
        showAlert: false,
        alertTitle: '',
        alertMessage: '',
        listMissing: [],

        showDeleteAlert: false,
        showChangeTableAlert: false,
        newSourceSelected: '',

        jsonQuery: { "id": "g-moBH5kR6vJG3EcemPOWRz", "rules": [], "combinator": "and", "not": false },
        sqlQuery: '',
        showMoreGroupByDropdown: false,
        groupBySelected: '',
        orderByColumns: 0,
        orderByColumnsSelected: [],
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.selectedRow !== this.props.selectedRow) {
            if(this.props.selectedRow){
                this.rowClickFunction(this.props.selectedRow);
            } else {
                this.handleClearFieldsClick();
            }
            
        }
    }

    rowClickFunction = (obj) => {
        getReportFieldsBySourceName(obj['Source Name']).then(
            (json) => {
                if(json !== undefined)
                {
                switch (json.httpStatusCode) 
                {
                        case 200:
                        {
                            if(json.ReportFields !== undefined)
                            {
                                var list = json.ReportFields;
                                list.forEach(field => {
                                    field.value = field.FieldName
                                    field.name  = field.FieldName
                                });
                                this.setState({
                                    reportFieldsList: list
                                });
                                getFieldsByCustomReportId(obj['Report Id']).then(
                                    (valueResponse)=>{
                                        if(valueResponse !== undefined)
                                        {
                                            switch (valueResponse.httpStatusCode) {
                                                case 200:
                                                {
                                                    //if(valueResponse.FieldsByCustomReport !== undefined)
                                                    if(valueResponse !== undefined)
                                                    {
                                                        var actualSourceIdByReportId = obj['Report Source Id'];
                                                        var losQueHayAhora = list;
                                                        losQueHayAhora = losQueHayAhora.filter(
                                                            field => field.ReportSourceId === parseInt(actualSourceIdByReportId)
                                                        );

                                                        //var losQueTieneAhora = valueResponse.FieldsByCustomReport;
                                                        debugger;
                                                        var losQueTieneAhora = valueResponse.data;
                                                        losQueTieneAhora.forEach(element => {
                                                            element.value = element.ReportFieldName;//element.ReportFieldId;
                                                            element.name = element.ReportFieldName;//element.Name
                                                            element.Name = element.ReportFieldName;
                                                            element.label = element.ReportFieldName
                                                        });

                                                        losQueHayAhora.forEach(element => {
                                                            element.Name = element.FieldName;
                                                            element.label = element.FieldName
                                                        });
                                                        var newLeftList = losQueHayAhora.filter(
                                                            ({ value: id1 }) => !losQueTieneAhora.some(
                                                                ({ value: id2 }) => id2 === id1)
                                                        );

                                                        var newrightList = losQueTieneAhora.filter(elm => !losQueHayAhora.map(elm => JSON.stringify(elm)).includes(JSON.stringify(elm)));  

                                                        ////debugger
                                                        let jsonQueryValue = {"id":"g-moBH5kR6vJG3EcemPOWRz","rules":[],"combinator":"and","not":false};
                                                        if (obj.JSONQuery && obj.JSONQuery !== ''){
                                                            jsonQueryValue = JSON.parse(obj.JSONQuery);
                                                        }
                                                        debugger;
                                                        this.setState({
                                                            sourceSelected: actualSourceIdByReportId,
                                                            validationSource: true,
                                                            rightList: newrightList,
                                                            validationRightList: true,
                                                            leftList: newLeftList,
                                                            reportName: obj['Report Name'],
                                                            validationReportName: true,
                                                            customReportId: obj['Report Id'],
                                                            sqlQuery: obj.SQLQuery,
                                                            jsonQuery: jsonQueryValue
                                                        }, () => {
                                                            let rList = this.state.rightList || []
                                                            if (this.props.selectedRow !== undefined && this.props.selectedRow !== null &&
                                                                this.props.selectedRow.OrderByColumns !== undefined && this.props.selectedRow.OrderByColumns !== null) {
                                                                let words = this.props.selectedRow.OrderByColumns.split(',');                                        

                                                                if (!Array.isArray(words) && words.length === 0) {
                                                                    return
                                                                }
                                                                words = words.map(x => x.trim())                                        
                                                                let selectedColumns = []

                                                                for (var j = 0; j < words.length; j++) {
                                                                    for (var i = 0; i < rList.length; i++) {
                                                                        if (words[j].includes('\'' + rList[i].Name + '\' asc')) {
                                                                            rList[i].orderBy = 'asc'
                                                                            selectedColumns.push(rList[i])
                                                                        } else if (words[j].includes('\'' + rList[i].Name + '\' desc')) {
                                                                            rList[i].orderBy = 'desc'
                                                                            selectedColumns.push(rList[i])
                                                                        } else if (words[j].includes('\'' + rList[i].Name)) {
                                                                            rList[i].orderBy = 'asc'
                                                                            selectedColumns.push(rList[i])
                                                                        }
                                                                    }
                                                                }
                                                                if (selectedColumns.length > 0) {
                                                                    this.setState({ orderByColumnsSelected: selectedColumns, orderByColumns: selectedColumns.length });
                                                                }
                                                            }
                                                        });
                                                    }
                                                    break;
                                                }
                                                default:
                                                {
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                );   
                            }
                            break;
                        }
                        default:
                        { 
                            this.setState({
                                showAlert  : true, 
                                alertMessage    : 'Something went wrong loading the data. Please try again later', 
                                alertTitle      : 'Error',
                            });
                            break;
                        }
                }
                }
            }
        );     
    }

    componentDidMount =  () => {
        getReportSources().then(
            (json) => {
               if(json !== undefined)
               {
                   switch (json.httpStatusCode) 
                   {
                        case 200:
                        {
                            if(json.ReportSources !== undefined)
                            {
                                var list = json.ReportSources;
                                list.forEach(field => {
                                    field.value = field['Report Source Id']
                                    field.name  = field.Name
                                });
                                this.setState({
                                    sourceList: list
                                });
                            }
                            break;
                        }
                        default:
                        { 
                            this.setState({
                                showAlert  : true, 
                                alertMessage    : 'Something went wrong loading the data. Please try again later', 
                                alertTitle      : 'Error',
                            });
                            break;
                        }
                   }
               }
                if(this.props.selectedRow){
                    this.rowClickFunction(this.props.selectedRow);
                }
            }
        );

        /*getReporFields().then(
            (json) => {
               if(json !== undefined)
               {
                   switch (json.httpStatusCode) 
                   {
                        case 200:
                        {
                            if(json.ReportFields !== undefined)
                            {
                                var list = json.ReportFields;
                                list.forEach(field => {
                                    field.value = field.ReportFieldId
                                    field.name  = field.Name
                                });
                                this.setState({
                                    reportFieldsList: list
                                });
                            }
                            break;
                        }
                        default:
                        { 
                            this.setState({
                                showAlert  : true, 
                                alertMessage    : 'Something went wrong loading the data. Please try again later', 
                                alertTitle      : 'Error',
                            });
                            break;
                        }
                   }
               }
            }
        );*/
    }

    handleGetValueSource = (sourceObj) => {
        if (sourceObj.value !== undefined) 
        {
            getReportFieldsBySourceName(sourceObj.name).then(
                (json) => {
                   if(json !== undefined)
                   {
                       switch (json.httpStatusCode) 
                       {
                            case 200:
                            {
                                if(json.ReportFields !== undefined)
                                {
                                    var list = json.ReportFields;
                                    list.forEach(field => {
                                        field.value = field.FieldName//field.ReportFieldId
                                        field.name  = field.FieldName
                                    });
                                    if(this.state.customReportId === 0)
                                    {
                                        this.setState({
                                            sourceSelected          : sourceObj.value,
                                            validationSource        : true,
                                        }, () =>
                                        {
                                            var losQueHayAhora = list;
                                            losQueHayAhora = losQueHayAhora.filter(
                                                field => field.ReportSourceId === parseInt(sourceObj.value)
                                            );
                                            this.setState({
                                                leftList    : losQueHayAhora,
                                                rightList   : []
                                            });
                                        });
                                    }else
                                    {
                                        if(sourceObj.value !== '' )
                                        {
                                            if(this.state.newSourceSelected === '')
                                            {
                                                this.setState({
                                                    showChangeTableAlert: true,
                                                    newSourceSelected   : sourceObj.value
                                                });
                                            }else
                                            {
                                                this.setState({
                                                    sourceSelected          : sourceObj.value,
                                                    validationSource        : true,
                                                }, () =>
                                                {
                                                    var losQueHayAhora = list;
                                                    losQueHayAhora = losQueHayAhora.filter(
                                                        field => field.ReportSourceId === parseInt(sourceObj.value)
                                                    );
                                                    this.setState({
                                                        leftList: losQueHayAhora,
                                                        rightList   : []
                                                    });
                                                });
                                            }
                                            
                                        }else{
                                            this.setState({
                                                sourceSelected      : '',
                                                leftList            : [],
                                                rightList           : []
                                            });
                                        }
                                    }                                    
                                    this.setState({
                                        reportFieldsList: list
                                    });
                                }
                                break;
                            }
                            default:
                            { 
                                this.setState({
                                    showAlert  : true, 
                                    alertMessage    : 'Something went wrong loading the data. Please try again later', 
                                    alertTitle      : 'Error',
                                });
                                break;
                            }
                       }
                   }
                }
            );
        }
    }

    assignFields = (sourceObj) => {
        if(this.state.customReportId === 0)
        {
            this.setState({
                sourceSelected          : sourceObj.value,
                validationSource        : true,
            }, () =>
            {
                var losQueHayAhora = this.state.reportFieldsList;
                losQueHayAhora = losQueHayAhora.filter(
                    field => field.ReportSourceId === parseInt(sourceObj.value)
                );
                this.setState({
                    leftList    : losQueHayAhora,
                    rightList   : []
                });
            });
        }else
        {
            if(sourceObj.value !== '' )
            {
                if(this.state.newSourceSelected === '')
                {
                    this.setState({
                        showChangeTableAlert: true,
                        newSourceSelected   : sourceObj.value
                    });
                }else
                {
                    this.setState({
                        sourceSelected          : sourceObj.value,
                        validationSource        : true,
                    }, () =>
                    {
                        var losQueHayAhora = this.state.reportFieldsList;
                        losQueHayAhora = losQueHayAhora.filter(
                            field => field.ReportSourceId === parseInt(sourceObj.value)
                        );
                        this.setState({
                            leftList: losQueHayAhora,
                            rightList   : []
                        });
                    });
                }
                
            }else{
                this.setState({
                    sourceSelected      : '',
                    leftList            : [],
                    rightList           : []
                });
            }
        }
    }

    handleGetGroupByValue = (value) => {

    }

    updateReportName = (event) =>{
        this.setState({
            validationReportName    : true,
            reportName              : event.target.value
        });
    }

    changeColorLeft = selectedIDRowLeft => e => {
        if (selectedIDRowLeft !== undefined) {
            this.setState({ selectedIDRowLeft  });
        }
    };

    changeColorRight = selectedIDRowRight => e => {        
        ////debugger
        if (selectedIDRowRight !== undefined) {
            this.setState({ selectedIDRowRight });
        }
    };

    //Buttons
    addItemSelected = () =>
    {
        ////debugger
        var leftL = this.state.leftList;
        var rightL = this.state.rightList;
        
        var idSelected = this.state.selectedIDRowLeft;
        var add = true
        
        debugger;
        if(idSelected != '')
        {
            if(rightL.length == 0)
            {
                leftL.forEach((field, index) => {
                    debugger;
                    //if(field.ReportFieldId == idSelected)
                    if(field.FieldName == idSelected)
                    {
                        rightL.push(field)
                        leftL.splice(index,1)
                    }

                    this.setState({
                        rightList           : rightL,
                        leftList            : leftL,
                        selectedIDRowLeft   : '',
                        validationRightList : true,
                    });
                });
            }else
            {
                rightL.forEach(rightField => {
                    //if(rightField.ReportFieldId == idSelected)
                    if(rightField.FieldName == idSelected)
                    {
                        add = false
                    }
                });
                if(add)
                {
                    leftL.forEach((leftField, index) => {
                        //if(leftField.ReportFieldId == idSelected)
                        if(leftField.FieldName == idSelected)
                        {
                            rightL.push(leftField)
                            leftL.splice(index,1)

                            this.setState({
                                rightList           : rightL,
                                leftList            : leftL,
                                selectedIDRowLeft   : '',
                                validationRightList : true,
                            });
                        }
                    });
                }
            }
        }
    }

    removeItemSelected = () =>
    {
        debugger
        var leftL = this.state.leftList;
        var rightL = this.state.rightList;
        
        var idSelected = this.state.selectedIDRowRight;

        if(idSelected != '')
        {
            rightL.forEach((rightField, index) => {
                //if(rightField.ReportFieldId == idSelected)
                debugger
                if(rightField.name == idSelected)
                {
                    rightL.splice(index,1)
                    leftL.push(rightField)
                }
            });

            this.setState({
                rightList           : rightL,
                leftList            : leftL,
                selectedIDRowRight  : ''
            });
        }
    }

    addAllItems = () =>
    {
        var leftL = this.state.leftList;
        var rightL = this.state.rightList;

        var newListLeft = []
        leftL.forEach((leftField, index) => {
            newListLeft.push(leftField)
        })
        
        Array.prototype.push.apply(rightL,newListLeft)

        this.setState({
            rightList           : rightL,
            leftList            : [],
            selectedIDRowLeft   : '',
            selectedIDRowRight  : ''
        });
    }
    
    removeAll = () =>
    {
        var leftL = this.state.leftList;
        var rightL = this.state.rightList;

        var newListRight = []
        rightL.forEach((rightField, index) => {
            newListRight.push(rightField)
        })

        Array.prototype.push.apply(leftL,newListRight)

        this.setState({
            rightList           : [],
            leftList            : leftL,
            selectedIDRowLeft   : '',
            selectedIDRowRight  : '',
        });
    }

    moveItemInRightList = (up) => {
        var rightList = this.state.rightList;

        let currentItem;
        let latterItem;
        let newList = [];
        var idSelected = this.state.selectedIDRowRight;
        if (rightList.length <= 1) {
            return
        }

        let pushLatter = false;
        for (var i = 0; i < rightList.length; i++) {
            let wi = rightList[i]
            currentItem = wi
            let fieldToCompare = wi.ReportFieldName || wi.Name
            if (fieldToCompare === idSelected) {

                if (up && newList.length > 0) {
                    newList.pop()
                    newList.push(currentItem)
                    newList.push(latterItem)
                }

                if (up && newList.length === 0) {
                    newList.push(currentItem)
                }

                if (!up && i === rightList.length - 1) {
                    newList.push(currentItem)
                }

                if (!up && i < rightList.length - 1) {
                    pushLatter = true;
                }
            }
            else {
                newList.push(wi);
                if (pushLatter) {
                    newList.push(latterItem);
                    pushLatter = false;
                }
            }
            latterItem = wi
        }
        this.setState({ rightList: newList })
    }

    closeAlert = () => {
        this.setState({
            showAlert       : false, 
            alertMessage    : '', 
            alertTitle      : '', 
        });
    }

    handleSaveClick = () => 
    {
        if(this.validateFields())
        {
            let orderByColumns = ''
            if (this.state.orderByColumnsSelected !== undefined && Array.isArray(this.state.orderByColumnsSelected)
                && this.state.orderByColumnsSelected.length > 0) {
                orderByColumns = this.state.orderByColumnsSelected.map((obj) => '\'' + obj.Name + '\'' + ((obj.orderBy !== undefined && obj.orderBy !== null) ? (' ' + obj.orderBy) : '')).join(', ')
            }            

            var model =
            {
                "ReportId": this.state.customReportId,
                "Name": this.state.reportName,
                "CreatedBy": localStorage.getItem('UserID'),
                "ReportSourceId": this.state.sourceSelected,
                "SQLQuery": this.state.sqlQuery,
                "JSONQuery": JSON.stringify(this.state.jsonQuery),
                "FieldsQuery": JSON.stringify(this.state.rightList),
                "OrderByColumns": orderByColumns
            }

            if (model.SQLQuery === '()'){
                model.SQLQuery = '';
            }

            saveCustomReport(model).then(
                (jsonResponseSave) => {
                    if(jsonResponseSave !== undefined)
                    {
                        switch (jsonResponseSave.httpStatusCode) 
                        {
                            case 200:
                            {
                                if(jsonResponseSave.CustomReportId !== undefined && jsonResponseSave.CustomReportId !== "")
                                {
                                    var fieldsByCustomReportsList = [];
                                    var actualList = this.state.rightList;

                                    debugger;
                                    actualList.forEach(element => {
                                        debugger;
                                        let field = {
                                            ReportId        : jsonResponseSave.CustomReportId,
                                            //ReportFieldId   : element.ReportFieldId
                                            ReportFieldName   : element.value
                                        }
                                        fieldsByCustomReportsList.push(field);
                                    });

                                    var objToBody = 
                                    {
                                        FieldsByCustomReportsJson: JSON.stringify(fieldsByCustomReportsList)
                                    }

                                    saveFieldsByCustomReports(objToBody).then(
                                        (jsonResponseF) => {
                                            if(jsonResponseF !== undefined)
                                            {                                                
                                                /*saveFieldsByCustomReports: {"Result":true,"httpStatusCode":200}*/
                                                switch (jsonResponseF.httpStatusCode) 
                                                {
                                                    case 200:
                                                    {
                                                        if(jsonResponseF.Result === true)
                                                        {
                                                            this.setState({
                                                                showAlert       : true,
                                                                alertTitle      : 'Success',
                                                                alertMessage    : 'Custom Report successfully saved.',
                                                            }, ()=>
                                                            {
                                                                //this.updateCustomReportList();
                                                                this.props.returnToList();
                                                                //this.handleClearFieldsClick();
                                                            });
                                                        }
                                                        break;
                                                    }
                                                    default:
                                                        break;
                                                }

                                            }
                                        }
                                    );
                                }
                                break;
                            }
                            default:
                            {
                                this.setState({
                                    showAlert       : true,
                                    alertTitle      : 'Error',
                                    alertMessage    : 'Something went wrong. Please try again later',
                                });
                                break;
                            }
                        }
                    }
                }
            );
        }
    }

    handleClearFieldsClick = () => {
        this.setState({
            customReportId: 0,
            reportName: '',
            sourceSelected: '',
            leftList: [],
            rightList: [],
            listMissing: [],
            validationReportName: true,
            validationSource: true,
            validationRightList: true,
            orderByColumns: 0,
            orderByColumnsSelected: []
        });
    }

    handleOrderByClick = () => {
        let orderByColumns = this.state.orderByColumns
        if (this.state.rightList.length === orderByColumns) {
            return
        }
        this.setState({ orderByColumns: orderByColumns + 1 })
    }

    validateFields()
    {
        var valid = true
        var reportNameTF = true
        var sourceSelectedTF = true
        var rightListTF = true

        var listItems = []

        if(this.state.reportName === undefined || this.state.reportName === '')
        {
            valid = false;
            reportNameTF = false;
            listItems.push("Report Name");
        }

        if(this.state.sourceSelected === undefined || this.state.sourceSelected === '' || this.state.sourceSelected === '0')
        {
            valid = false;
            sourceSelectedTF = false;
            listItems.push("Select a Source.");
        }

        if(this.state.rightList.length === 0)
        {
            valid = false;
            rightListTF = false;
            listItems.push("Add some Fields.");
        }

        this.setState({
            validationReportName    : reportNameTF,
            validationSource        : sourceSelectedTF,
            validationRightList     : rightListTF,
            listMissing             : listItems
        });

        if (!valid) {
            this.setState({
                showAlert    : true,
                alertTitle   : 'Error',
                alertMessage : 'Please fill all mandatory fields:'
            });
        }

        return valid;
    }

    onCloseDialog = () =>
    {
        this.setState({
            open                 : false,
            dataCustomReportList : [],
            customReportName     : '',
            maxWidth             : 'md',
            currentPage          : 1,
            totalPages           : 0
        });
    }

    changePage = (value) => {
        this.setState({
            currentPage: value
        });
    }

    logQuery = (query) => {
        this.setState({jsonQuery: query, sqlQuery: formatQuery(query, 'sql')})
    }

    yesChangeSourceAlert = () =>
    {
        var listReports = this.state.customReportsList;
        var actualSourceSelected = '';

        listReports.forEach(report => {
            if(report['Report Id'] === this.state.customReportId)
            {
                actualSourceSelected = report['Report Source Id']
            }
        });

        this.setState({
            sourceSelected          : this.state.newSourceSelected,
            validationSource        : true,
        }, () =>
        {
            var losQueHayAhora = this.state.reportFieldsList;
            losQueHayAhora = losQueHayAhora.filter(
                field => field.ReportSourceId === parseInt(this.state.sourceSelected)
            );
            this.setState({
                leftList            : losQueHayAhora,
                rightList           : [],
                showChangeTableAlert: false,
            });
        });
    }

    closeChangeSourceAlert = () =>
    {
        this.setState({
            showChangeTableAlert : false,
        });
    }

    updateOrderByColumnsSelected = (columns) => {        
        this.setState({ orderByColumnsSelected: columns, orderByColumns: columns.length });
    }

    render = () => {       
        return (
            <div className="uk-margin">
                <ul id="accordion-compliance" uk-accordion="multiple: true" >
                        <li className="uk-open">
                            <h5 className="uk-accordion-title">Report Information</h5>
                            <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                                <div className="uk-grid" style={{marginTop: "0px"}}>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label" style={style.boldLabel} >Report Name</label>
                                        <div className="" style={style.inputStyle}>
                                            <input 
                                                className   = {formatValidInput(this.state.validationReportName)}
                                                type        = 'text'
                                                value       = {this.state.reportName}
                                                onChange    = {this.updateReportName}
                                            />
                                        </div>
                                    </div>
                                    <div className="uk-width-1-2 uk-form-stacked">
                                        <label className="uk-form-label" style={style.boldLabel}>Source</label>
                                        <div className="" style={style.inputStyle}>
                                            <DynamicSelect
                                                id                  = "form-custom-report-source"
                                                className           = {formatValidSelect(this.state.validationSource)}
                                                objValue            = {{value: this.state.sourceSelected}} 
                                                getValue            = {this.handleGetValueSource} 
                                                placeholder         = 'Select a Source...'
                                                listHasPlaceholder  = {false}
                                                data                = {this.state.sourceList}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="uk-open">
                            <h5 className="uk-accordion-title">Report Fields</h5>
                            <div className="uk-accordion-content" style={{backgroundColor: '#f8f8f8'}}>
                                <div id="report-fields">
                                    <div style={{ display: 'flex' }}>
                                        <h3 className="uk-width-1-3" style={{ marginLeft: 20, textAlign: 'center' }}>Available Fields</h3>
                                        <h3 className="uk-width-1-3"></h3>
                                        <h3 className="uk-width-1-3" style={{ textAlign: 'center' }}>Assigned Fields</h3>
                                    </div>
                                    <div className="" style={{ alignItems: 'center', display: 'flex', border: this.state.validationRightList === true ? '1px solid #9DA99E' : '1px solid red' }}>
                                        <div className="uk-width-1-3 uk-form-stacked" style={{ overflowY: 'scroll', height: 300 }}>
                                            <table className="uk-table uk-table-hover uk-table-divider" style={{ marginBottom: 0 }}>
                                                <tbody>
                                                    {this.state.leftList.map((item, i) => {
                                                        return (
                                                            <tr 
                                                                key             = {item.value} 
                                                                onClick         = {this.changeColorLeft(item.value)} 
                                                                onDoubleClick   = {this.addItemSelected} 
                                                                style           = {this.state.selectedIDRowLeft === item.value ? { backgroundColor: Colors.primaryColor, cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }}
                                                            >
                                                                <td 
                                                                    style={this.state.selectedIDRowLeft === item.value ? { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }}
                                                                >
                                                                    {item.name}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}                                                
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="uk-card-body uk-width-1-3 uk-form-stacked" style={{ textAlign: 'center', borderRight: '1px solid #9DA99E', borderLeft: '1px solid #9DA99E' }}>
                                            <div>
                                            <button className="uk-button uk-button-green uk-width-1-2" style={{ marginBottom: 20 }} onClick={this.addItemSelected} >Add</button>
                                            <button className="uk-button uk-button-green uk-width-1-2" style={{ marginBottom: 20 }} onClick={this.addAllItems}>Add all</button>
                                            <button className="uk-button uk-button-green uk-width-1-2" style={{ marginBottom: 20 }} onClick={this.removeItemSelected}>Remove</button>
                                            <button className="uk-button uk-button-green uk-width-1-2" onClick={this.removeAll}>Remove all</button>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', float: 'right', marginTop: '-160px' }}
                                                hidden={this.state.selectedIDRowRight === ''}>
                                                <Button key={'b10up'} value={"up"} onClick={() => { this.moveItemInRightList(true) }} icon={<UpOutlined />} style={{ padding: '5px' }} ></Button>
                                                <Button value={"b10down"} onClick={() => { this.moveItemInRightList(false) }} icon={<DownOutlined />} ></Button>
                                            </div>
                                        </div>
                                        <div className="uk-width-1-3 uk-form-stacked" style={{ overflowY: 'scroll', height: 300, paddingLeft: 0 }}>
                                        <table className="uk-table uk-table-hover uk-table-divider" style={{ marginBottom: 0 }}>
                                            <tbody>                                                
                                                {this.state.rightList.map((item) => {
                                                    return (
                                                        <tr
                                                            key={item.value}
                                                            onClick={this.changeColorRight(item.value)}
                                                            onDoubleClick={this.removeItemSelected}
                                                            style={this.state.selectedIDRowRight === item.value ? { backgroundColor: '#c3824a', cursor: 'pointer' } : { backgroundColor: 'white', cursor: 'pointer' }
                                                            }
                                                        >
                                                            <td style={this.state.selectedIDRowRight === item.value ? { color: 'white', cursor: 'pointer' } : { color: 'black', cursor: 'pointer' }}>
                                                                {item.name}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-margin">
                                    <h4>Query Builder</h4>
                                    <QueryBuilder fields={this.state.rightList} onQueryChange={this.logQuery} query={this.state.jsonQuery} controlClassnames={queryBuilderCSS} />
                                    <button onClick={this.handleOrderByClick}>+Order by</button>
                                    <div className="uk-width-1-2" style={style.inputStyle}>
                                        <OrderBySection rightList={this.state.rightList}
                                            orderByColumns={this.state.orderByColumns}
                                            orderByColumnsSelected={this.state.orderByColumnsSelected}
                                            updateOrderByColumnsSelected={this.updateOrderByColumnsSelected}
                                        />
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div>
                        <button 
                            className="uk-button uk-button-green uk-margin-right" 
                            onClick={this.handleClearFieldsClick}
                        >ClearFields</button>
                        <button 
                            className="uk-button uk-button-green uk-margin-right" 
                            onClick={this.handleSaveClick}
                        >Save</button>
                    </div>
            </div>
        );
    }
}