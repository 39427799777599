import SideNav, { NavIcon, NavItem, NavText } from '@trendmicro/react-sidenav'
import React from 'react'
import { NotificationContainer } from 'react-notifications'
import {
  BrowserRouter,
  BrowserRouter as Router,
  Outlet,
  Route,
  Routes,
  Switch,
} from 'react-router-dom'
import styled from 'styled-components'
import './App.scss'
import { AppProvider } from './AppContext'
import BankReconciliationScreen from './components/back_office/bankReconciliation/BankReconciliationScreen'
import ApprovalBoardScreen from './components/back_office/boards/ApprovalBoardScreen'
import BeneficiaryBoardsScreen from './components/back_office/boards/BeneficiaryBoardsScreen'
import BeneficiariesCardViewScreen from './components/back_office/boards/cardBoard/beneficiaryCardView/BeneficiariesCardViewScreen'
import CardBoardScreen from './components/back_office/boards/cardBoard/CardBoardScreen'
import CustomersCardViewScreen from './components/back_office/boards/cardBoard/customerCardView/CustomersCardViewScreen'
import CheckerBoardsScreen from './components/back_office/boards/CheckerBoardsScreen'
import ComplaintBoardScreen from './components/back_office/boards/ComplaintBoardScreen'
import ComplianceApprovedBoardsScreen from './components/back_office/boards/ComplianceApprovedBoardsScreen'
import CustomerBoardsScreen from './components/back_office/boards/CustomerBoardsScreen'
import DealBoardsScreen from './components/back_office/boards/DealBoardsScreen'
import DepositBoardsScreen from './components/back_office/boards/DepositBoarsScreen'
import HeldFundsBoardScreen from './components/back_office/boards/HeldFundsBoardScreen'
import MakerBoardsScreen from './components/back_office/boards/MakerBoardsScreen'
import PaymentBoardsScreen from './components/back_office/boards/PaymentBoarsScreen'
import SwiftBoardScreen from './components/back_office/boards/SwiftBoardScreen'
import WorkAssignmentBoardsScreen from './components/back_office/boards/WorkAssignmentBoardsScreen'
import CreditApprovalScreen from './components/back_office/creditapproval/CreditApprovalScreen'
import CustomerOverviewScreen from './components/back_office/CustomerOverviewScreen'
import DepositsScreen from './components/back_office/deposits/DepositsScreen'
import EmailsScreen from './components/back_office/emails/EmailsScreen'
import EmailTemplatesScreen from './components/back_office/emailTemplates/EmailTemplatesScreen'
import EndOfDayProcessScreen from './components/back_office/EndOfDayProcessScreen'
import HeldFundsBalanceTransferForm from './components/back_office/heldFundsBalanceTransfer/HeldFundsBalanceTransferForm'
import MarginCallsScreen from './components/back_office/marginCalls/MarginCallsScreen'
import NostroBalancesScreen from './components/back_office/nostroBalances/NostroBalancesScreen'
import InstantBeneficiaryPaymentsAFEX from './components/back_office/payments/InstantBeneficiaryPaymentsAFEX'
import CustomReportsScreen from './components/back_office/reports/customReports/NewCustomReportsScreen'
import CustomReportsV2Screen from './components/back_office/reports/customReports/NewCustomReportsV2Screen'
import ReportsScreen from './components/back_office/reports/ReportsScreen'
import SettlementsScreen from './components/back_office/settlements/SettlementsScreen'
import BeneficiaryHandlingScreen from './components/compliance/BeneficiaryHandlingScreen'
import FilesReviewScreen from './components/compliance/FilesReviewScreen'
//import ContactMaintenanceScreen from './components/pre_trade/customers/contacts/ContactMaintenanceScreen';
import ReferredDealsScreen from './components/compliance/referred_deals/ReferredDealsScreen'
import RiskScoreRangeScreen from './components/compliance/RiskScoreRangeScreen'
import SuspiciousDealsSearchScreen from './components/compliance/suspicious_deals/SuspiciousDealsSearchScreen'
import DashboardScreen from './components/dashboard/DashboardScreen'
import DealSearchScreen from './components/deal/DealSearchScreen'
import LoginForm from './components/login/LoginForm'
import ResetPasswordForm from './components/login/ResetPasswordForm'
import PropertiesMaintenanceScreen from './components/maintenance/additional_properties/PropertiesMaintenanceScreen'
import AfterHoursScreen from './components/maintenance/afterHours/AfterHoursScreen'
import BankBranchScreen from './components/maintenance/bankBranches/BankBranchScreen'
import BlotterScreen from './components/maintenance/blotter/BlotterScreen'
import NewCountryScreen from './components/maintenance/country/NewCountryScreen'
import NewCurrencyScreen from './components/maintenance/currency/NewCurrencyScreen'
import CurrencyPairScreen from './components/maintenance/currencyPair/CurrencyPairScreen'
import CurrencyPairMaintenanceScreen from './components/maintenance/currencyPairMaintenance/CurrencyPairMaintenanceScreen'
import FeeStructureScreen from './components/maintenance/feeStructure/FeeStructureScreen'
import FeeMaintenanceScreen from './components/maintenance/fee_maintenance/FeeMaintenanceScreen'
import ForwardPointsScreen from './components/maintenance/forward_points/ForwardPointsScreen'
import GlobalSystemParametersUserScreen from './components/maintenance/globalSystemParametersUser/GlobalSystemParametersUserScreen'
import HolidaysMaintenanceScreen from './components/maintenance/holidays/HolidaysMaintenanceScreen'
import LookUpTableScreen from './components/maintenance/lookUpTableDetails/LookUpTableScreen'
import NostroAccountsScreen from './components/maintenance/nostro_accounts/NostroAccountsScreen'
import OnlineUserProfilesScreen from './components/maintenance/OnlineUserProfiles/OnlineUserProfilesScreen'
import NewPricingScreen from './components/maintenance/pricing_new/NewPricingScreen'
import RatesMaintenanceScreen from './components/maintenance/rates/NewRatesMaintenanceScreen'
import RegistrationSettingsScreen from './components/maintenance/registration_settings/RegistrationSettingsScreen'
import RiskFactorsMaintenanceScreen from './components/maintenance/risk_factors/RiskFactorsMaintenanceScreen'
import TasksMaintenanceScreen from './components/maintenance/tasks_maintenance/TasksMaintenanceScreen'
import TreasuryPaddingMaintenanceScreen from './components/maintenance/treasury_padding/TreasuryPaddingMaintenanceScreen'
import VolatilityPaddingMaintenanceScreen from './components/maintenance/volatility_padding/VolatilityPaddingMaintenanceScreen'
import ChangePassword from './components/my_account/ChangePassword'
import CustomersScreen from './components/pre_trade/customers/CustomersScreen'
import DynamicCoverScreen from './components/pre_trade/customers/dynamicCover/DynamicCoverScreen'
import NewBeneficiaryMaintenance from './components/pre_trade/customers/newBeneficiary/NewBeneficiaryMaintenance'
import SuccessBeneficiary from './components/pre_trade/customers/newBeneficiary/SuccessBeneficiary'
import ActionsScreen from './components/security/actions/ActionsScreen'
import NewGlobalSystemParametersScreen from './components/security/global_system_parameters/NewGlobalSystemParametersScreen'
import RolesScreen from './components/security/roles/RolesScreen'
import UserScreen from './components/security/users/UserScreen'
//import EndOfDayValidationScreen from './components/back_office/EndOfDayValidationScreen';
import AlertBox from './components/shared/AlertBox'
import LoadingBox from './components/shared/LoadingBox'
import CashProjection from './components/tools/CashProjection'
import DealMonitorScreen from './components/tools/deal_monitor/DealMonitorScreen'
import PandLPivotScreen from './components/tools/PandLPivotScreen'
import PDFScreen from './components/tools/pdf/PDFScreen'
import PositionMonitor from './components/tools/PositionMonitor'
import CoverDealApplicationScreen from './components/trade/cover_deal_application/CoverDealApplicationScreen'
import ImportDealsScreen from './components/trade/importDeals/ImportDealsScreen'
import IntegrationsMaintenanceScreen from './components/trade/integrationsMaintenance/IntegrationsMaintenanceScreen'
import RateMonitoringScreen from './components/trade/monitoring/RateMonitoringScreen'
import OrderBoardScreen from './components/trade/order_board/OrderBoardScreen'
import RollOverScreen from './components/trade/rollOver/RollOverScreen'
import CreateTransfersScreen from './components/trade/Transfers/CreateTransfersScreen'
import WizardScreen from './components/trade/wizard/WizardScreen'
import './css/font-awesome.min.css'
import './css/react-sidenav.css'
import 'react-notifications/lib/notifications.css'
import { userHasActions } from './helpers/UserActionsHelper'
import {
  logout,
  validateSession,
  refreshVersionLogout,
} from './networking/Networking'
import { getCurrencies } from './networking/NetworkingCurrencies'
import { getAllCurrencyPairs } from './networking/NetworkingCurrencyPairs'
import { getParametersByName } from './networking/NetworkingGlobalSystemParameters'
import RouterMenu from './router/RouterMenu'
import withRouter from './router/withRouter'
import HeldFundsBalanceTransferScreen from './components/back_office/heldFundsBalanceTransfer/HeldFundsBalanceTransferScreen'
import NewFeeMaintenanceScreen from './components/maintenance/fee_maintenance/NewFeeMaintenanceScreen'
import NewEmailsScreen from './components/back_office/emails/NewEmailsScreen'
import NewPropertiesMaintenanceScreen from './components/maintenance/additional_properties/NewPropertiesMaintenanceScreen'
import MessagesScreen from './components/back_office/messages/MessagesScreen'
import ShowGlobalWatchList from './components/maintenance/watchList/GlobalWatchListScreen'
import HistoryData from './components/maintenance/historyData/HistoryData'
import DynamicScreenTest from './components/maintenance/dynamicScreen/DynamicScreenTest'
import CasesScreen from './components/tmx/cases/CasesScreen'
import { Colors } from './constants/Colors'
import NewRiskScoreRangeScreen from './components/compliance/NewRiskScoreRangeScreen'
import NewGlobalScreen from './components/security/global_system_parameters/NewDynamicGlobalParametersScreen'
import TransactionMonitoringBoardScreen from './components/back_office/boards/TransactionMonitoringBoardScreen'
import NewDynamicGlobalParametersScreen from './components/security/global_system_parameters/NewDynamicGlobalParametersScreen'
import FirstReviewScreen from './components/back_office/boards/FirstReviewScreen'
import SecondReviewScreen from './components/back_office/boards/SecondReviewScreen'
import NewDynamicRolesScreen from './components/security/roles/NewDynamicRolesScreen'
import NewDynamicActionsScreen from './components/security/actions/NewDynamicActionsScreen'
import SchedulerScreen from './components/pre_trade/scheduler/SchedulerScreen'
import ReminderScreen from './components/pre_trade/scheduler/ReminderScreen'
import { getLookUpTablesDescription } from './networking/NetworkingLookUpTables'
import { getCustomersByTrader } from './networking/NetworkingCustomers'
//import * as menuJson from './menu/menu.json';
//let menuJson = require('menu/menu.json');
const actionsList = require('./helpers/ActionsList.json')
// console.log(actionsList);

const Main = styled.main`
  position: relative;
  overflow: hidden;
  transition: all 0.15s;
  padding: 0 20px;
  margin-left: ${(props) => (props.expanded ? 240 : 64)}px;
`

const StyledSideNav = styled(SideNav)`
  background-color: ${Colors.primaryColor};
  position: fixed;
  list-style: none;
`
StyledSideNav.defaultProps = SideNav.defaultProps

const componentsArray = {
  DashboardScreen: {
    component: DashboardScreen,
  },
  CustomersScreen: {
    component: CustomersScreen,
  },
  CustomersCardViewScreen: {
    component: CustomersCardViewScreen,
  },
  BeneficiariesCardViewScreen: {
    component: BeneficiariesCardViewScreen,
  },
  PropertiesMaintenanceScreen: {
    component: PropertiesMaintenanceScreen,
  },
  TasksMaintenanceScreen: {
    component: TasksMaintenanceScreen,
  },
  LookUpTableScreen: {
    component: LookUpTableScreen,
  },
  NewCountryScreen: {
    component: NewCountryScreen,
  },
  NewCurrencyScreen: {
    component: NewCurrencyScreen,
  },
  DynamicCoverScreen: {
    component: DynamicCoverScreen,
  },
  ForwardPointsScreen: {
    component: ForwardPointsScreen,
  },
  NewPricingScreen: {
    component: NewPricingScreen,
  },
  FeeMaintenanceScreen: {
    component: FeeMaintenanceScreen,
  },
  FeeStructureScreen: {
    component: FeeStructureScreen,
  },
  HolidaysMaintenanceScreen: {
    component: HolidaysMaintenanceScreen,
  },
  RiskFactorsMaintenanceScreen: {
    component: RiskFactorsMaintenanceScreen,
  },
  RatesMaintenanceScreen: {
    component: RatesMaintenanceScreen,
  },
  TreasuryPaddingMaintenanceScreen: {
    component: TreasuryPaddingMaintenanceScreen,
  },
  VolatilityPaddingMaintenanceScreen: {
    component: VolatilityPaddingMaintenanceScreen,
  },
  CurrencyPairScreen: {
    component: CurrencyPairScreen,
  },
  NostroAccountsScreen: {
    component: NostroAccountsScreen,
  },
  AfterHoursScreen: {
    component: AfterHoursScreen,
  },
  BlotterScreen: {
    component: BlotterScreen,
  },
  WizardScreen: {
    component: WizardScreen,
  },
  OrderBoardScreen: {
    component: OrderBoardScreen,
  },
  RollOverScreen: {
    component: RollOverScreen,
  },
  CreateTransfersScreen: {
    component: CreateTransfersScreen,
  },
  RolesScreen: {
    component: RolesScreen,
  },
  UserScreen: {
    component: UserScreen,
  },
  ActionsScreen: {
    component: ActionsScreen,
  },
  NewGlobalSystemParametersScreen: {
    component: NewGlobalSystemParametersScreen,
  },
  ReferredDealsScreen: {
    component: ReferredDealsScreen,
  },
  RiskScoreRangeScreen: {
    component: RiskScoreRangeScreen,
  },
  SuspiciousDealsSearchScreen: {
    component: SuspiciousDealsSearchScreen,
  },
  DealMonitorScreen: {
    component: DealMonitorScreen,
  },
  CustomerOverviewScreen: {
    component: CustomerOverviewScreen,
  },
  DealSearchScreen: {
    component: DealSearchScreen,
  },
  CustomerBoardsScreen: {
    component: CustomerBoardsScreen,
  },
  BeneficiaryBoardsScreen: {
    component: BeneficiaryBoardsScreen,
  },
  BankReconciliationScreen: {
    component: BankReconciliationScreen,
  },
  DealBoardsScreen: {
    component: DealBoardsScreen,
  },
  PaymentBoardsScreen: {
    component: PaymentBoardsScreen,
  },
  EndOfDayProcessScreen: {
    component: EndOfDayProcessScreen,
  },
  ReportsScreen: {
    component: ReportsScreen,
  },
  CustomReportsScreen: {
    component: CustomReportsScreen,
  },
  CustomReportsV2Screen: {
    component: CustomReportsV2Screen,
  },
  PDFScreen: {
    component: PDFScreen,
  },
  DepositBoardsScreen: {
    component: DepositBoardsScreen,
  },
  DepositsScreen: {
    component: DepositsScreen,
  },
  WorkAssignmentBoardsScreen: {
    component: WorkAssignmentBoardsScreen,
  },
  MakerBoardsScreen: {
    component: MakerBoardsScreen,
  },
  CheckerBoardsScreen: {
    component: CheckerBoardsScreen,
  },
  ApprovalBoardScreen: {
    component: ApprovalBoardScreen,
  },
  HeldFundsBoardScreen: {
    component: HeldFundsBoardScreen,
  },
  SwiftBoardScreen: {
    component: SwiftBoardScreen,
  },
  ComplianceApprovedBoardsScreen: {
    component: ComplianceApprovedBoardsScreen,
  },
  CardBoardScreen: {
    component: CardBoardScreen,
  },
  PositionMonitor: {
    component: PositionMonitor,
  },
  PandLPivotScreen: {
    component: PandLPivotScreen,
  },
  MarginCallsScreen: {
    component: MarginCallsScreen,
  },
  RateMonitoringScreen: {
    component: RateMonitoringScreen,
  },
  CoverDealApplicationScreen: {
    component: CoverDealApplicationScreen,
  },
  CreditApprovalScreen: {
    component: CreditApprovalScreen,
  },
  BeneficiaryHandlingScreen: {
    component: BeneficiaryHandlingScreen,
  },
  FilesReviewScreen: {
    component: FilesReviewScreen,
  },
  CurrencyPairMaintenanceScreen: {
    component: CurrencyPairMaintenanceScreen,
  },
  ImportDealsScreen: {
    component: ImportDealsScreen,
  },
  OnlineUserProfilesScreen: {
    component: OnlineUserProfilesScreen,
  },
  BankBranchScreen: {
    component: BankBranchScreen,
  },
  RegistrationSettingsScreen: {
    component: RegistrationSettingsScreen,
  },
  EmailTemplatesScreen: {
    component: EmailTemplatesScreen,
  },
  EmailsScreen: {
    component: EmailsScreen,
  },
  HistoryData: {
    component: HistoryData,
  },
  GlobalSystemParametersUserScreen: {
    component: GlobalSystemParametersUserScreen,
  },
  SettlementsScreen: {
    component: SettlementsScreen,
  },
  HeldFundsBalanceTransferForm: {
    component: HeldFundsBalanceTransferForm,
  },
  InstantBeneficiaryPaymentsAFEX: {
    component: InstantBeneficiaryPaymentsAFEX,
  },
  CashProjection: {
    component: CashProjection,
  },
  IntegrationsMaintenanceScreen: {
    component: IntegrationsMaintenanceScreen,
  },
  NostroBalancesScreen: {
    component: NostroBalancesScreen,
  },
  ComplaintBoardScreen: {
    component: ComplaintBoardScreen,
  },
  DynamicScreenTest: {
    component: DynamicScreenTest,
  },
  CasesScreen: {
    component: CasesScreen,
  },
  TransactionMonitoringBoardScreen: {
    component: TransactionMonitoringBoardScreen,
  },
  SchedulerScreen: {
    component: SchedulerScreen,
  },
}

const getComponentFromComponentsArray = (index, props) => {
  return React.createElement(componentsArray[index].component, props)
}

export default class extends React.PureComponent {
  state = {
    expanded: false,
    notLogin: false,
    sessionValid: false,
    showGenericAlert: false,
    showSessionInvalidAlert: false,
    loading: false,
    //My Account
    showChangePassword: false,
    normalMenu: [],
    complianceMenu: [],
    forceChangePassword: false,
    events:[],
    openEventsPopup: false,
    reminderList:[],
    eventTypes:[],
    eventStatusList:[],
    customersList:[]
  }

  componentDidMount() {
    fetch(window.REACT_APP_HOME_URL + 'menu/menu.json').then(async (value) => {
      this.setState({ normalMenu: await value.json() })
    })
    fetch(window.REACT_APP_HOME_URL + 'menu/complianceMenu.json').then(
      async (value) => {
        this.setState({ complianceMenu: await value.json() })
      }
    )
    var session = setInterval(
      this.sessionTick.bind(this),
      window.REACT_APP_SESSION_TICK
    )
    this.setState({ sessionFunc: session })

    let alreadyLogin = localStorage.getItem('AlreadySessionCreated')

    if (alreadyLogin) {
      let sessionCreatedOn = localStorage.getItem('SessionCreatedOn')
      let now = Date.now()
      let timeDiff = (now - sessionCreatedOn) / 1000
      if (timeDiff < 3600) {
        let screenPermisions = JSON.parse(
          localStorage.getItem('ScreenShowPermissions')
        )
        this.setState({ sessionValid: true, notLogin: true })
        this.setState(screenPermisions)
      } else {
        localStorage.clear()
      }
    }    
  }

  componentWillUnmount() {
    clearInterval(this.state.sessionFunc)
  }

  onToggle = (expanded) => {
    this.setState({ expanded: expanded })
  }
  toggleShowChangePassword = () => {
    let forceChangePassword = this.state.forceChangePassword
    if (forceChangePassword) {
      this.setState({
        showChangePassword: !this.state.showChangePassword,
        forceChangePassword: false,
        sessionValid: false,
        notLogin: false,
      })
    } else {
      this.setState({ showChangePassword: !this.state.showChangePassword })
    }
  }

  startLoading = () => {
    this.setState({ loading: true })
  }

  finishLoading = () => {    
    this.setState({ loading: false })
  }

  handleLoginViewChangePassword = () => {
    this.setState(
      { showChangePassword: true, forceChangePassword: true },
      () => {
        this.handleLoginClick()
      }
    )
  }

  handleLoginClick = () => {
    localStorage.setItem('AlreadySessionCreated', true)
    this.setState({ sessionValid: true }, () => {
      this.setState({ loading: true }, () => {
        getParametersByName(
          'SystemDate,CutoffTime,LastClosingDate,NumberOfDaysToCreateBaseDDForEOD,NumberOfDaysToCreateNoneBaseDDForEOD,BaseCurrency,EnableFundsOnHoldInPaymentScreen,TradeScreenAllowAdditionalSettlements,NostroAccountGuid,ForwardPointsScale,BackgroundColorRiskScore,BeneficiaryHandlingMethodBackend,ForwardDepositDecimals,TradeScreenEnableBuyBackDeal,DefaultDealFeeStructure,ConveraBatchModelCurrencies,CreateBeneficiaryFromCustomer,AllowCreateBeneficiaryUser'
        ).then((value) => {
          if (value.parameters !== undefined) {
            for (var i = 0; i < value.parameters.length; i++) {
              localStorage.setItem(
                value.parameters[i].Name,
                value.parameters[i].Value
              )
            }
            this.setState({ loading: false, notLogin: true })
          }
        })

        getCurrencies(0, 0).then((json) => {
          var helper = this.formatCurrencies(json.currencies)
          localStorage.setItem('Currencies', JSON.stringify(helper))
        })

        getAllCurrencyPairs().then((json) => {
          localStorage.setItem(
            'CurrencyPairs',
            JSON.stringify(json.currencyPairs)
          )
        })

        userHasActions(actionsList.actions).then((value) => {
          console.log(value)

          localStorage.setItem('ScreenShowPermissions', JSON.stringify(value))
          this.setState(value)
        })

        getLookUpTablesDescription('Event Status, Event Type, Reminder').then(
          (jsonResponse) => {
              if(jsonResponse.lookUpTables !== undefined)
              {
                  if(Array.isArray(jsonResponse.lookUpTables))
                  {
                    jsonResponse.lookUpTables.map(lookupTable => {
                        let objLookUpTable = lookupTable.lookUpTableDetails
                        if(Array.isArray(objLookUpTable))
                        {
                            let list = []
                            objLookUpTable.forEach(element => {
                                var elementList = {
                                    value   :element.LookUpTableDetail,
                                    name    : element.LookUpTableDetail
                                }
                                list.push(elementList)
                            });
                            switch(lookupTable.LookUpTableDescription)
                            {
                              case 'Reminder': this.setState({reminderList:list}); break;
                              case 'Event Type': this.setState({eventTypes:list}); break;
                                case 'Event Status': this.setState({eventStatusList:list}); break;
                              default: return true;
                            }
                        }
                    });
                }
              }
          }
        )
        getCustomersByTrader().then(
          (response) => {
              if (response.customers !== undefined) {
                  for (var i = 0; i < response.customers.length; i++) {
                      response.customers[i].value = response.customers[i].CustomerID;
                      response.customers[i].name = response.customers[i].CustomerIDName;
                  }
                 this.setState({customersList: response.customers});
              }
          }
      )

        this.reloadEvents();
      })
    })
  }

  formatCurrencies(collection) {
    var helper = collection
    for (var i = 0; i < helper.length; i++) {
      let dem = helper[i]['Min Denomination'].toString()
      dem = dem.replace(/0/g, ' ').trim()
      if (dem.indexOf('.') > -1) {
        helper[i]['Scale'] = dem.toString().length - (dem.indexOf('.') + 1)
      } else {
        helper[i]['Scale'] = 0
      }
    }
    return helper
  }

  closeSessionAlert = () => {
    this.setState({
      showSessionInvalidAlert: false,
      sessionValid: false,
      notLogin: false,
    })
  }

  sessionTick() {
    let stopGettingSession =
      localStorage.getItem('StopGettingSession') === 'true' || false

    if (this.state.sessionValid && !stopGettingSession) {
      validateSession().then((value) => {
        if (value !== undefined) {
          
          if (value.httpStatusCode !== 200) {
            this.setState({
              showSessionInvalidAlert: true,
              sessionValid: false,
            })
          }
        } else {          
          this.setState({ showSessionInvalidAlert: true, sessionValid: false })
        }
        if(value.events && value.events.length > 0)
        {
          this.setState({events: value.events});
        }
        else{
          this.setState({ openEventsPopup: false, events: []})
        }

        let alreadyLogin = localStorage.getItem('AlreadySessionCreated')
        if (!alreadyLogin) {
          this.setState({ showSessionInvalidAlert: true, sessionValid: false })
        }
      })
    }
  }

  handleLogoutClick = (history) => {
    logout().then((value) => {
      if (value != null) {
        this.setState({
          notLogin: false,
          showSessionInvalidAlert: false,
          sessionValid: false,
          expanded: false,
        })
        localStorage.clear()
        if (history) {
          history.push('/')
        }
      } else {
        window.alert('Check your Internet Connection.')
      }
    })
  }

  isJsonString = (str) => {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  reloadEvents = () =>{
    validateSession().then((value) => {
      if (value !== undefined) {
        
        if (value.httpStatusCode !== 200) {
          this.setState({
            showSessionInvalidAlert: true,
            sessionValid: false,
          })
        }
      } else {          
        this.setState({ showSessionInvalidAlert: true, sessionValid: false })
      }
      if(value.events && value.events.length > 0)
      {
        this.setState({events: value.events});
      }
      else{
        this.setState({ events: [], openEventsPopup: false})
      }
    }
    )
  }

  render() {
    /*if (JSON.parse(localStorage.getItem('HasComplianceAccess'))) {
      menuJson = require('menu/complianceMenu.json');
    } else {
      menuJson = require('menu/menu.json');
    }*/
    let menuJson = [];
    if (localStorage.getItem('HasComplianceAccess') !== undefined && this.isJsonString(localStorage.getItem('HasComplianceAccess')) && JSON.parse(localStorage.getItem('HasComplianceAccess'))) {
      menuJson = this.state.complianceMenu;
    } else {
      console.log('normalMenu', this.state.normalMenu);
      menuJson = this.state.normalMenu;
    }
    const { expanded } = this.state;    

    if (window.location.pathname.substring(0, 15) === "/resetPassword/") {
      let resetPasswordKey = window.location.pathname.substring(15) || ''
      if (resetPasswordKey.length > 36) {
        resetPasswordKey = resetPasswordKey.substring(0, 36)
      }
      return (
        <div>
          {/* <Router>
              <Route exact={true} path="/" component={() => <div>Test</div>}/>
              <Route path="/resetPassword/:token" component={({match, history}) => <ResetPasswordForm match={match} history={history} />} />
            </Router> */}
          <BrowserRouter>
            <Routes>
              <Route path="/resetPassword/:token" element={<ResetPasswordForm resetPasswordKey={resetPasswordKey} />} />
            </Routes>
          </BrowserRouter>
        </div>
      )
    }

    if (
      window.location.pathname.substring(0, '/NewBeneficiary'.length) ===
      '/NewBeneficiary'
    ) {
      return (
        <div>
          {/* <BrowserRouter>
            <Switch>
              <Route exact={true} path="/" component={() => <div>New Beneficiary Test</div>} />
              <Route path="/SuccessBeneficiary" component={({ match, history }) => <SuccessBeneficiary match={match} history={history} />} />
              <Route path="/:key" component={({ match, history }) => <NewBeneficiaryMaintenance match={match} history={history} />} />
            </Switch>
          </BrowserRouter> */}
          <BrowserRouter>
            <Routes>
              <Route
                path="/SuccessBeneficiary"
                element={withRouter(SuccessBeneficiary)}
              />
            </Routes>
          </BrowserRouter>
        </div>
      )
    }
    if (!this.state.notLogin) {
      return (
        <AppProvider
          value={{
            startLoading: this.startLoading,
            finishLoading: this.finishLoading,
            forceLogout: this.handleLogoutClick,
          }}
        >
          <div className="App">
            <LoginForm
              loginSuccess={this.handleLoginClick}
              logout={this.handleLogoutClick}
              loginViewChangePassword={this.handleLoginViewChangePassword}
            />
            <LoadingBox loading={this.state.loading} />
          </div>
        </AppProvider>
      )
    }
    return (
      <BrowserRouter>
        <AppProvider
          value={{
            startLoading: this.startLoading,
            finishLoading: this.finishLoading,
            forceLogout: this.handleLogoutClick,
            toggleShowChangePassword: this.toggleShowChangePassword,
          }}
        >
          <div>
            <Routes>
              <Route path="/" element={<RouterMenu events={this.state.events.length} onEventsClick={()=> this.setState({openEventsPopup:true})} />}>
                <Route path="dashboard" element={<DashboardScreen />} />

                {this.state.ShowTrading && (
                  <Route path="trade/new-trade" element={<WizardScreen />} />
                )}
                {this.state.ShowPaymentOrderBoard && (
                  <Route
                    path="trade/order-board"
                    element={<OrderBoardScreen />}
                  />
                )}
                {this.state.ShowRollOver && (
                  <Route path="trade/roll-over" element={<RollOverScreen />} />
                )}
                {this.state.ShowPositionMonitor && (
                  <Route
                    path="trade/position-monitor"
                    element={<PositionMonitor />}
                  />
                )}
                {this.state.ShowPositionRevaluation && (
                  <Route
                    path="trade/profit-and-loss"
                    element={<PandLPivotScreen />}
                  />
                )}
                {this.state.ShowDepositInfo && (
                  <Route
                    path="trade/margin-calls"
                    element={<MarginCallsScreen />}
                  />
                )}
                {this.state.ShowRateWatchMonitorTrade && (
                  <Route
                    path="trade/rate-monitor"
                    element={<RateMonitoringScreen />}
                  />
                )}
                {this.state.ShowDealMonitor && (
                  <Route
                    path="trade/deal-monitor"
                    element={<DealMonitorScreen />}
                  />
                )}
                {this.state.ShowCoverDealApplication && (
                  <Route
                    path="trade/cover-deal-application"
                    element={<CoverDealApplicationScreen />}
                  />
                )}
                {this.state.ShowDealTransfer && (
                  <Route
                    path="trade/transfers"
                    element={<CreateTransfersScreen />}
                  />
                )}
                {this.state.ShowImportDealsScreen && (
                  <Route
                    path="trade/import-deals"
                    element={<ImportDealsScreen />}
                  />
                )}
                {this.state.ShowCashProjection && (
                  <Route
                    path="trade/cash-projection"
                    element={<CashProjection />}
                  />
                )}
                {this.state.ShowIntegrationsMaintenance && (
                  <Route
                    path="trade/integration-maintenance"
                    element={<IntegrationsMaintenanceScreen />}
                  />
                )}

                {this.state.ShowSearchCustomer && (
                  <Route
                    path="pre-trade/customers"
                    element={<CustomersScreen />}
                  />
                )}
                {this.state.ShowCustomer360 && (
                  <Route
                    path="pre-trade/customer-overview"
                    element={<CustomerOverviewScreen />}
                  />
                )}
                {this.state.ShowCustomerCardView && (
                  <Route
                    path="pre-trade/customer-card-view"
                    element={<CustomersCardViewScreen />}
                  />
                )}
                {this.state.ShowScheduler && (
                  <Route
                    path="pre-trade/scheduler"
                    element={<SchedulerScreen  reminderList={this.state.reminderList}
                    eventTypeList={this.state.eventTypes} 
                      customers={this.state.customersList}
                      eventStatusList={this.state.eventStatusList} />}
                  />
                )}
                {this.state.ShowAdminRoles && (
                  <Route
                    path="security/roles"
                    element={<NewDynamicRolesScreen />}
                  />
                )}
                {this.state.ShowAdminUsers && (
                  <Route path="security/users" element={<UserScreen />} />
                )}
                {this.state.ShowAdminActions && (
                  <Route
                    path="security/actions"
                    element={<NewDynamicActionsScreen />}
                  />
                )}
                {this.state.ShowGlobalParamatersMaintenance && (
                  <Route
                    path="security/global-system-parameters"
                    element={<NewDynamicGlobalParametersScreen />}
                  />
                )}

                {this.state.ShowReferredDeals && (
                  <Route
                    path="compliance/referred-deals"
                    element={<ReferredDealsScreen />}
                  />
                )}
                {this.state.ShowRiskScoreRangeMaintenance && (
                  <Route
                    path="compliance/risk-score-range"
                    element={<NewRiskScoreRangeScreen />}
                  />
                )}
                {this.state.ShowTaskListTemplateDetails && (
                  <Route
                    path="compliance/tasks"
                    element={<TasksMaintenanceScreen />}
                  />
                )}
                {this.state.ShowWorkAssignmentBoard && (
                  <Route
                    path="compliance/work-assignments-board"
                    element={<WorkAssignmentBoardsScreen />}
                  />
                )}
                {this.state.ShowMakerBoard && (
                  <Route
                    path="compliance/maker-board"
                    element={<MakerBoardsScreen />}
                  />
                )}
                {this.state.ShowCheckerBoard && (
                  <Route
                    path="compliance/checker-board"
                    element={<CheckerBoardsScreen />}
                  />
                )}
                {this.state.ShowFirstReviewBoard && (
                  <Route
                    path="compliance/first-review-board"
                    element={<FirstReviewScreen />}
                  />
                )}
                {this.state.ShowSecondReviewBoard && (
                  <Route
                    path="compliance/second-review-board"
                    element={<SecondReviewScreen />}
                  />
                )}
                {this.state.ShowCountryCompliance && (
                  <Route
                    path="compliance/compliance-approved-board"
                    element={<ComplianceApprovedBoardsScreen />}
                  />
                )}
                {this.state.ShowSuspiciousDeals && (
                  <Route
                    path="compliance/suspicious-deals-search"
                    element={<SuspiciousDealsSearchScreen />}
                  />
                )}
                {this.state.ShowRiskFactorMaintenance && (
                  <Route
                    path="compliance/risk-factor"
                    element={<RiskFactorsMaintenanceScreen />}
                  />
                )}
                {this.state.ShowBeneficiaryHandlingMaintenance && (
                  <Route
                    path="compliance/beneficiary-handling"
                    element={<BeneficiaryHandlingScreen />}
                  />
                )}
                {this.state.ShowFilesReviewScreen && (
                  <Route
                    path="compliance/files-review"
                    element={<FilesReviewScreen />}
                  />
                )}
                {this.state.ShowGlobalWatchList && (
                  <Route
                    path="compliance/global-watch-list"
                    element={<ShowGlobalWatchList />}
                  />
                )}
                {this.state.ShowTransactionMonitoring && (
                  <Route
                    path="compliance/transaction-monitoring-board"
                    element={<TransactionMonitoringBoardScreen />}
                  />
                )}

                {this.state.ShowBankReconciliation && (
                  <Route
                    path="back-office/bank-reconciliation"
                    element={<BankReconciliationScreen />}
                  />
                )}
                {this.state.ShowDealBoard && (
                  <Route
                    path="back-office/deal-boards"
                    element={<DealBoardsScreen />}
                  />
                )}
                {this.state.ShowPaymentBoard && (
                  <Route
                    path="back-office/payments-boards"
                    element={<PaymentBoardsScreen />}
                  />
                )}
                {this.state.ShowDepositBoard && (
                  <Route
                    path="back-office/margin-boards"
                    element={<DepositBoardsScreen />}
                  />
                )}
                {this.state.ShowCustomerBoard && (
                  <Route
                    path="back-office/customers-boards"
                    element={<CustomerBoardsScreen />}
                  />
                )}
                {this.state.ShowBeneficiaryBoard && (
                  <Route
                    path="back-office/beneficiary-boards"
                    element={<BeneficiaryBoardsScreen />}
                  />
                )}
                {this.state.ShowBeneficiaryCardView && (
                  <Route
                    path="back-office/beneficiary-card-view"
                    element={<BeneficiariesCardViewScreen />}
                  />
                )}
                {this.state.ShowApprovalBoard && (
                  <Route
                    path="back-office/approval-board"
                    element={<ApprovalBoardScreen />}
                  />
                )}
                {this.state.ShowHeldFundsBalanceTransfer && (
                  <Route
                    path="back-office/held-funds-board"
                    element={<HeldFundsBoardScreen />}
                  />
                )}
                {this.state.ShowOutstandingDealCardView && (
                  <Route
                    path="back-office/deal-cards"
                    element={<CardBoardScreen />}
                  />
                )}
                {this.state.ShowSwiftBoardScreen && (
                  <Route
                    path="back-office/swift-board"
                    element={<SwiftBoardScreen />}
                  />
                )}
                {this.state.ShowSearchDeal && (
                  <Route
                    path="back-office/deal-search"
                    element={<DealSearchScreen fromBackOffice={true} />}
                  />
                )}
                {this.state.ShowCreditApproval && (
                  <Route
                    path="back-office/credit-approval"
                    element={<CreditApprovalScreen />}
                  />
                )}
                {this.state.ShowProcessEndOfDayProcess && (
                  <Route
                    path="back-office/end-of-day-process"
                    element={<EndOfDayProcessScreen />}
                  />
                )}
                {this.state.ShowNostroAccounts && (
                  <Route
                    path="back-office/nostro-accounts"
                    element={<NostroAccountsScreen />}
                  />
                )}
                {this.state.ShowSettlements && (
                  <Route
                    path="back-office/settlements"
                    element={<SettlementsScreen />}
                  />
                )}
                {this.state.ShowHeldFundsBalanceTransfer && (
                  <Route
                    path="back-office/held-funds-balance-transfer"
                    element={<HeldFundsBalanceTransferScreen />}
                  />
                )}
                {this.state.ShowInstantBeneficiaryPaymentsAFEX && (
                  <Route
                    path="back-office/afex-instant-beneficiary-payments"
                    element={<InstantBeneficiaryPaymentsAFEX />}
                  />
                )}
                {this.state.ShowNostroBalances && (
                  <Route
                    path="back-office/nostro-balances"
                    element={<NostroBalancesScreen />}
                  />
                )}
                {this.state.ShowComplaintsScreen && (
                  <Route
                    path="back-office/complaints"
                    element={<ComplaintBoardScreen />}
                  />
                )}
                {this.state.ShowMessages && (
                  <Route
                    path="back-office/messages"
                    element={<MessagesScreen />}
                  />
                )}

                {this.state.ShowPricingMaintenance && (
                  <Route
                    path="rate-and-pricing/pricing"
                    element={<NewPricingScreen />}
                  />
                )}
                {this.state.ShowDynamicPricingDefault && (
                  <Route
                    path="rate-and-pricing/dynamic-cover"
                    element={<DynamicCoverScreen />}
                  />
                )}
                {this.state.ShowForwardPointsMaintenance && (
                  <Route
                    path="rate-and-pricing/forward-points"
                    element={<ForwardPointsScreen />}
                  />
                )}
                {this.state.ShowRatesMaintenance && (
                  <Route
                    path="rate-and-pricing/rates"
                    element={<RatesMaintenanceScreen />}
                  />
                )}
                {this.state.ShowRateTreasuryPadding && (
                  <Route
                    path="rate-and-pricing/treasury-padding"
                    element={<TreasuryPaddingMaintenanceScreen />}
                  />
                )}
                {this.state.ShowVolatilityPaddingMaintenance && (
                  <Route
                    path="rate-and-pricing/volatility-padding"
                    element={<VolatilityPaddingMaintenanceScreen />}
                  />
                )}
                {this.state.ShowBlotterDefsMaintenance && (
                  <Route
                    path="rate-and-pricing/blotter"
                    element={<BlotterScreen />}
                  />
                )}

                {this.state.ShowLookUpDetails && (
                  <Route
                    path="maintenance/lookup-tables"
                    element={<LookUpTableScreen />}
                  />
                )}
                {this.state.ShowCountryMaintenance && (
                  <Route
                    path="maintenance/countries"
                    element={<NewCountryScreen />}
                  />
                )}
                {this.state.ShowCurrencyMaintenance && (
                  <Route
                    path="maintenance/currencies"
                    element={<NewCurrencyScreen />}
                  />
                )}
                {this.state.ShowFeeMaintenance && (
                  <Route
                    path="maintenance/fee-maintenance"
                    element={<NewFeeMaintenanceScreen />}
                  />
                )}
                {this.state.ShowFeeStructuresMaintenance && (
                  <Route
                    path="maintenance/fee-structure"
                    element={<FeeStructureScreen />}
                  />
                )}
                {this.state.ShowHolidaysPerCurrency && (
                  <Route
                    path="maintenance/holidays"
                    element={<HolidaysMaintenanceScreen />}
                  />
                )}
                {this.state.ShowCurrencyPairMaintenance && (
                  <Route
                    path="maintenance/currency-pair-maintenance"
                    element={<CurrencyPairMaintenanceScreen />}
                  />
                )}
                {this.state.ShowAfterHoursMaintenance && (
                  <Route
                    path="maintenance/after-hours"
                    element={<AfterHoursScreen />}
                  />
                )}
                {this.state.ShowBanks && (
                  <Route
                    path="maintenance/banks"
                    element={<BankBranchScreen />}
                  />
                )}
                {this.state.ShowUserDefinedProperties && (
                  <Route
                    path="maintenance/additional-properties"
                    element={<PropertiesMaintenanceScreen />}
                  />
                )}
                {this.state.ShowUserDefinedPropertiesV2 && (
                  <Route
                    path="maintenance/additional-properties-v2"
                    element={<NewPropertiesMaintenanceScreen />}
                  />
                )}

                {this.state.ShowOnlineUserProfileMaintenance && (
                  <Route
                    path="maintenance/online-user-profiles"
                    element={<OnlineUserProfilesScreen />}
                  />
                )}
                {this.state.RegistrationSettingsScreen && (
                  <Route
                    path="maintenance/registration-settings"
                    element={<RegistrationSettingsScreen />}
                  />
                )}
                {this.state.ShowEmailTemplatesScreen && (
                  <Route
                    path="maintenance/email-templates"
                    element={<EmailTemplatesScreen />}
                  />
                )}
                {this.state.ShowEmailsScreen && (
                  <Route
                    path="maintenance/email"
                    element={<NewEmailsScreen />}
                  />
                )}
                <Route
                  path="maintenance/history-data"
                  element={<HistoryData />}
                />
                <Route
                  path="maintenance/dynamic-screen-test"
                  element={<DynamicScreenTest />}
                />
                <Route path="tmx/cases" element={<CasesScreen />} />
                {this.state.ShowReports && (
                  <Route path="reports/reports" element={<ReportsScreen />} />
                )}
                {this.state.ShowReports && (
                  <Route
                    path="reports/custom-reports"
                    element={<CustomReportsScreen />}
                  />
                )}
                {this.state.ShowReports && (
                  <Route
                    path="reports/custom-reports-v2"
                    element={<CustomReportsV2Screen />}
                  />
                )}

                <Route path="my-account" element={<></>} />
                <Route path="logout" element={<></>} />
              </Route>
            </Routes>
            <LoadingBox loading={this.state.loading} />
            <AlertBox
              key="genericAlert"
              id="generic-alert"
              open={this.state.showGenericAlert}
              title={this.state.titleGenericAlert}
              message={this.state.messageGenericAlert}
              type="Ok"
              okClick={this.closeGenericAlert}
            />
            <AlertBox
              key="sessionAlert"
              id="session-invalid"
              open={this.state.showSessionInvalidAlert}
              title="Invalid Session"
              message="Your connection has timed out. Please login again."
              type="Ok"
              okClick={this.closeSessionAlert}
            />
            <ChangePassword
              open={this.state.showChangePassword}
              close={this.toggleShowChangePassword}
            />
            <NotificationContainer />
            <ReminderScreen open={this.state.openEventsPopup} events={this.state.events}
            onClose={()=> this.setState({openEventsPopup:false})} 
            reminderList={this.state.reminderList}
            eventTypeList={this.state.eventTypes} 
              customers={this.state.customersList}
              eventStatusList={this.state.eventStatusList} 
              onReload={this.reloadEvents}
            />
          </div>
        </AppProvider>
      </BrowserRouter>
    )
  }
}

//export default App;
/* 
<Route path="/" exact component={props => <Root/>} />
<Route path="/contents" component={props => <Contents/>} />
<Route path="/devices" component={props => <Devices/>} />
<Route path="/engines" component={props => <Engines/>}/>
<Route path="/allocations" component={props => <Allocations/>}/>
<Route path="/libraries" component={props => <Libraries/>}/>

*/
